import {mapGetters} from 'vuex'
import config from '@/config/index'

export default {
  name: 'baseInfoMixins',
  props: {
    mid: {
      type: Number,
      default: null
    },
    midData: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      chartLoading: false,
      query: null,
      detail: {},
    }
  },
  computed: {
    ...mapGetters(['height']),
    baseInfoHeight() {
      return this.query ? this.height - 268 : this.height - 173
    },
    config() {
      return config
    }
  },
  mounted() {
    this.query = this.$router.currentRoute.params.id
  },
  methods: {},
};
